<script>
	import Setup from "./Setup.vue";
	import PasswordPage from "./PasswordPage.vue";

	// TODO: run a client-side check to see if token and userid is present.
	// If not, just don't display the page. Or error...
	export default {
		name: "SetNew",
		props: ["pageSubtitle", "userId", "redirectUrl", "isMFAV2"],
		components: {
			PasswordPage,
			Setup,
		},
		data() {
			return {
				error: "",
				loading: false,
				token: "",
				success: "",
				output: {
					password: "",
					confirmPassword: "",
				},
			};
		},
		methods: {
			handleFormSubmit() {
				let data = {
					Password: btoa(this.output.password),
					ConfirmPassword: btoa(this.output.confirmPassword),
					UserId: this.userId,
				};

				this.error = "";
				if (data.Password == "" && data.ConfirmPassword == "") {
					this.error = "Password fields cannot be blank.";
				} else if (data.ConfirmPassword == "") {
					this.error = "Confirm Password field cannot be blank.";
				} else if (data.Password == "") {
					this.error = "Password field cannot be blank.";
				}
				if (this.error != "" || !this.output.passed) return;

				this.loading = true;
				const __this = this;
				const saveUrl = this.isMFAV2 ? "/Password/reset" : "/Password/SetNew";

				this.$ajax
					.post(saveUrl, data, {
						headers: {
							Authorization: this.token,
						},
					})
					.then((resp) => {
						if (typeof resp.data != "undefined") {
							if (resp.data.result === "FAILED") {
								this.error = resp.data.message;
								this.loading = false;
							} else {
								this.success = "Password reset successful. Redirecting you to the login screen...";
								setTimeout(() => {
									__this.$router.push({
										name: "Login",
										query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
									});
								}, 5000);
							}
						}
					})
					.catch((error) => {
						if (error.result === "UNAUTHORIZED" || error.status === 401) {
							this.redirectTo(
								"The browser session has expired. Redirecting you to the Reset Pasword page...",
							);
						} else {
							this.loading = false;
							this.redirectTo(
								"Password reset has timed out. Go back to the login page and request a new password.",
								"Login",
							);
						}
					});
			},
			verifyToken() {
				this.$ajax
					.get("/Token/Validate", {
						headers: {
							Authorization: this.token,
						},
					})
					.catch((res) => {
						const {Valid} = res;
						if (!Valid) {
							this.redirectTo(
								"The browser session has expired. Redirecting you to the Reset Pasword page...",
							);
						}
					});
			},
			handlePwdRequestError(err) {
				if (err.status == 401) {
					this.redirectTo(
						"The browser session has expired. Redirecting you to the Reset Pasword page...",
					);
				} else this.error = "Something went wrong. Please try again later.";
			},
			redirectTo(message, page) {
				const router = this.$router;
				const name = page || "Recover";
				if (message) this.error = message;
				setTimeout(() => {
					router.push({
						name,
						query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
					});
				}, 3000);
			},
		},
		mounted() {
			this.token = this.$route.query.token || "";
			if (this.token) {
				this.verifyToken();
			}
		},
	};
</script>

<template>
	<setup title="Reset Password" :error="error" :success="success">
		<div class="card-body">
			<h3>{{ pageSubtitle }}</h3>
			<passwordPage
				:loading="loading"
				:isMFAV2="isMFAV2"
				v-model="output"
				:handleSubmit="handleFormSubmit"
				:onValidationRequestError="handlePwdRequestError"></passwordPage>
		</div>
	</setup>
</template>
