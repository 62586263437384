<template>
	<setup title="Select Your Identity" :error="error" :success="success">
		<form class="card-body" method="post" @submit.prevent="handleFormSubmit">
			<div style="text-align: center; padding: 20px 0 32px" v-if="initializing">
				<appIcons asset="loader" />
			</div>
			<div
				class="default-form w-border"
				style="text-align: center; margin: auto"
				v-if="!initializing">
				<div class="form-row label">
					<div class="form-label">Company</div>
					<select
						id="company"
						v-model="company"
						v-bind:disabled="companies === null"
						@change="setRolePickList"
						name="company">
						<option value="null" disabled selected>Select...</option>
						<option v-for="c in companies" v-bind:value="c.Id" :key="c.Id" v-cloak>
							{{ c.Name }} ({{ c.Id }})
						</option>
					</select>
				</div>
				<div class="form-row label" v-if="company !== null" v-cloak>
					<div class="form-label">Role</div>
					<select id="userRole" v-model="userRole" name="userRole">
						<option value="null" disabled selected>Select...</option>
						<option v-for="role in roles" v-bind:value="role.Code" :key="role.Code">
							{{ role.Description }}
						</option>
					</select>
				</div>
				<div class="mt-4" style="text-align: center">
					<div class="spinner small light" v-show="loading"></div>
					<input
						type="submit"
						value="Continue"
						class="button large blue"
						v-show="!loading"
						v-cloak />
				</div>
			</div>
		</form>
	</setup>
</template>

<script>
	import Setup from "./Setup.vue";
	import AppIcons from "./AppIcons.vue";
	export default {
		name: "Identity",
		data() {
			return {
				error: "",
				success: "",
				loading: false,
				initializing: true,
				companies: null,
				roles: [],
				roleList: [],
				company: null,
				userRole: null,
			};
		},
		components: {
			Setup,
			AppIcons,
		},
		methods: {
			setRolePickList() {
				if (this.roleList.length > 0) {
					this.userRole = null;
					const roles = this.roleList.filter(
						(r) => r.AllowedCompanies.includes(this.company) || r.AllowedCompanies === "*",
					);
					this.roles = roles || [];
				}
			},
			handleFormSubmit() {
				let data = {
					Company: this.company,
					Role: this.userRole,
					RedirectUrl: this.$route.query.redirectUrl,
				};
				this.error = "";
				if (data.Company == "") {
					this.error = "Please select a company.";
				}
				if (data.Role == "") {
					this.error = "Please select a Role.";
				}
				if (this.error != "") return;

				this.loading = true;
				this.reqAuthRedirect(data);
			},
			reqAuthRedirect(data) {
				this.$ajax
					.post("/Roles/authenticated-redirect", data)
					.then((resp) => {
						const {RedirectUrl, Message} = resp.data;
						this.success = Message;
						window.setTimeout(() => {
							window.location.href = RedirectUrl;
						}, 1000);
					})
					.catch((err) => {
						if (err.status == 401) {
							this.$router.push({
								name: "Login",
								query: {redirectUrl: this.$route.query.redirectUrl || undefined},
							});
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},

		async mounted() {
			const {Companies, Roles} = await this.$ajax.get("/Roles/details").then((resp) => resp.data);
			this.companies = Companies;
			this.roleList = Roles;
			if (Companies.length === 1 && Roles.length === 1) {
				let data = {
					Company: Companies[0].Id,
					Role: Roles[0].Code,
					RedirectUrl: this.$route.query.redirectUrl,
				};
				this.reqAuthRedirect(data);
			} else {
				this.initializing = false;
				this.companies = Companies;
				if (Companies.length === 1) {
					this.company = Companies[0].Id;
					this.setRolePickList();
					if (this.roles.length === 1) {
						this.userRole = this.roles[0].Code;
					}
				}
			}
		},
	};
</script>

<style scoped>
	.form-label {
		font-size: 14px;
		margin-bottom: 8px;
		line-height: 1.25;
	}

	.newui .section .default-form .form-row {
		margin-bottom: 24px;
	}

	.newui .section .default-form .form-row select {
		width: 100%;
		max-width: 320px;
	}

	.newui .section .default-form .form-row select {
		font-size: 14px;
		padding: 0 16px;
	}
</style>
