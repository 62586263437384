<template>
	<setup
		title="Confirm Your Identity"
		:error="error"
		:success="success"
		:hidePageTitle="hidePageTitle">
		<div class="otp-sending-progress" v-cloak v-if="sendingOtp">
			<div>
				<span>Sending your code</span>
			</div>
		</div>
		<div class="otp-sending-progress" v-cloak v-if="verifyingOtp">
			<div>
				Checking that code.
				<span style="white-space: nowrap">Please wait a moment...</span>
			</div>
		</div>
		<div v-if="verifyingStatus">
			Verifying token...<span style="white-space: nowrap">Please wait</span>
		</div>
		<form
			class="card-body"
			method="post"
			@submit.prevent="handleFormSubmit"
			novalidate
			v-if="!verifyingStatus">
			<div class="otp-input-group" v-if="showOtpInput">
				<p>
					<strong>Enter your verification code here then click to verify:</strong>
				</p>

				<div class="form-row">
					<div class="otp-control">
						<v-otp-input
							ref="otpInput"
							input-classes="otp-input"
							separator=""
							:num-inputs="6"
							:should-auto-focus="true"
							:is-input-num="false"
							@on-change="handleOtpChange"
							@on-complete="handleOtpComplete" />
					</div>
				</div>
				<div class="form-row">
					<div class="spinner small light" v-show="verifyingOtp"></div>
					<input
						type="submit"
						value="VERIFY"
						class="button large blue"
						v-show="!verifyingOtp"
						v-cloak
						ref="submitBtn" />
				</div>
			</div>
			<div class="default-form w-border" style="text-align: center; margin: auto">
				<p v-if="!showOtpInput">Where would you like us to send your verification code?<br /></p>
				<p v-if="showOtpInput">Need a new verification code?<br /></p>
				<div class="otp-block-options">
					<div class="otp-option" v-for="list in otpChannels" v-bind:key="list.Channel">
						<input
							type="radio"
							v-on:change="handleContactChange(list.Channel)"
							:value="list.Channel"
							:name="list.Channel"
							:id="list.Channel"
							v-bind:checked="list.Channel == selectedChannel" />
						<label :for="list.Channel"
							>Send it to my {{ `${getOptionLabel(list.Channel)}` }}
							<span>{{ `(${list.Contact})` }}</span></label
						>
					</div>
				</div>
				<div class="otp-block-button">
					<button
						type="button"
						class="button small green"
						v-cloak
						ref="btnSendOTP"
						v-on:click="sendOtp"
						v-bind:disabled="otpSending">
						<span v-show="!otpSending">{{ showOtpInput ? "RESEND" : "SEND" }}</span>
						<span class="spinner small light" v-show="otpSending"></span>
					</button>
				</div>
			</div>
		</form>
	</setup>
</template>

<style scoped>
	.card-body p {
		font-size: 14px;
		font-weight: bold;
	}
	.otp-block-options {
		text-align: left;
		max-width: 360px;
		margin: 0 auto 12px;
	}

	.otp-option {
		position: relative;
		padding-left: 24px;
	}

	.otp-option > * {
		vertical-align: middle;
		line-height: 24px;
		font-size: 12px;
	}
	.otp-option input {
		position: absolute;
		left: 0;
		top: 5px;
		height: 13px;
		width: 13px;
		margin: 0 8px 0 0;
	}
	@media screen and (min-width: 560px) {
		.otp-option label {
			white-space: nowrap;
		}
	}
	@media screen and (max-width: 559px) {
		.otp-option span {
			white-space: nowrap;
		}
	}

	.otp-block-option {
		margin-bottom: 4px;
	}

	.otp-block-button {
		margin-bottom: 20px;
	}
	.otp-block-button > button {
		width: 75px;
		height: 32px !important;
	}

	.otp-block-button > button:disabled {
		background-color: transparent !important;
		border-color: transparent !important;
	}

	.otp-input-group {
		margin-bottom: 32px;
	}
</style>

<script>
	import Setup from "./Setup.vue";
	export default {
		name: "MFA_V2",
		data() {
			return {
				sendingOtp: false,
				verifyingOtp: false,
				verifyingStatus: false,
				otpSending: false,
				selectedChannel: "",
				showOtpInput: false,
				retryCount: 0,
				code: "",
				token: "",
				error: "",
				success: "",
				hidePageTitle: false,
				page: undefined,
				redirectUrl: "",
				otpChannels: [],
			};
		},
		methods: {
			getOptionLabel(channel) {
				switch (channel) {
					case "sms":
						return "mobile";
					case "email":
						return "email";
				}
			},
			redirectToLogin(message) {
				const router = this.$router;
				this.error = message;
				setTimeout(() => {
					router.push({
						name: "Login",
						query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
					});
				}, 3000);
			},
			async sendOtp() {
				if (!this.selectedChannel) {
					this.error = `No contact has been selected.`;
					return false;
				}
				this.error = "";
				const body = {
					Channel: this.selectedChannel,
				};
				this.otpSending = true;
				this.retryCount = 0;
				this.$ajax
					.post(`/Mfa/request`, body, {
						withCredentials: true,
					})
					.then((resp) => {
						const {Success, Message} = resp.data;
						this.otpSending = false;
						if (Success) {
							this.showOtpInput = true;
							this.success = Message;
							window.setTimeout(() => {
								this.success = "";
							}, 5000);
						}
					})
					.catch((err) => {
						if (err.status == 401) {
							this.redirectToLogin(
								"The browser session has expired. Redirecting you to the login page...",
							);
						}
					});
			},
			handleContactChange(channel) {
				const {channel: qChannel} = this.$route.query;
				if (channel != this.selectedChannel) {
					this.selectedChannel = channel;
					this.showOtpInput = false;
				}

				if (channel == qChannel) {
					this.selectedChannel = channel;
					this.showOtpInput = true;
				}
			},
			handleFormSubmit() {
				if (!this.code || this.code.length < 6) {
					this.error = "Please enter your verification code.";
					return false;
				}

				this.error = "";
				this.verifyingOtp = true;
				this.$ajax
					.post(
						`/Mfa/verify`,
						{Otp: this.code, Channel: this.selectedChannel},
						{withCredentials: true},
					)
					.then(async (resp) => {
						const {Success, RedirectPage, Message} = resp.data;
						if (Success) {
							this.success = Message;
							setTimeout(() => {
								this.$router.push({
									name: RedirectPage,
									params: {isMFAV2: true},
									query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
								});
							}, 3000);
						} else {
							this.error = Message;
							this.verifyingOtp = false;
							this.$refs.otpInput.clearInput();
						}
					})
					.catch((err) => {
						if (err.status == 401) {
							this.redirectToLogin(
								"That verification code has expired. Redirecting you to the login page...",
							);
						} else {
							this.handleErrorResponse(err.status);
						}
					});
			},
			handleErrorResponse(status) {
				switch (status) {
					case "unknown": // Error on twilio side
						this.error = "Something went wrong, please enter it again later.";
						break;
					case "pending": // User inputted invalid OTP
						this.retryCount += 1;
						this.error =
							"That code is incorrect. You have 6 attempts at entering a valid code. Please try again.";
						break;
					case "expired": // OTP is expired
						this.error = this.page
							? "That verification code has expired. Redirecting you to the previous page..."
							: "OTP code is already expired. Please request a new one.";
						break;
					case "invalid-token": // OTP is expired
						this.error = this.page
							? "That verification code has expired. Redirecting you to the previous page..."
							: "OTP code is already expired. Please request a new one.";
						break;
					case "max-attempt": // Maximum attemp was reached
						this.error =
							"You only have 6 attempts at entering a valid code. Click RESEND to request a new code.";
						break;
				}
			},
			handleOtpChange(value) {
				this.code = value;
			},
			handleOtpComplete(value) {
				this.code = value;
				setTimeout(() => {
					this.$refs.submitBtn.focus();
				}, 10);
			},
		},
		components: {
			Setup,
		},
		async mounted() {
			const {Contacts} = await this.$ajax.get("/MFA/contact").then((resp) => resp.data);
			this.otpChannels = Contacts;
			this.redirectUrl = this.$route.query.redirectUrl;
		},
	};
</script>
