<script>
	import Setup from "./Setup.vue";
	export default {
		name: "SetupPassword",
		props: ["mfaNotSet", "otpChannels", "isMFAV2"],
		data() {
			return {
				error: "",
				loading: false,
				redirectUrl: "",
				output: {
					password: "",
					confirmPassword: "",
				},
			};
		},
		components: {
			Setup,
		},
		methods: {
			handleFormSubmit() {
				let data = {
					redirectUrl: this.redirectUrl,
					password: btoa(this.output.password),
					confirmPassword: btoa(this.output.confirmPassword),
				};
				this.error = "";
				if (data.password == "" && data.confirmPassword == "") {
					this.error = "Password fields cannot be blank.";
				} else if (data.confirmPassword == "") {
					this.error = "Confirm Password field cannot be blank.";
				} else if (data.password == "") {
					this.error = "Password field cannot be blank.";
				}
				if (this.error != "" || !this.output.passed) return;

				this.loading = true;

				this.$ajax
					.post("/Setup/Password", data)
					.then(async (resp) => {
						if (typeof resp.data != "undefined") {
							const {
								Error,
								ClientRedirect,
								RedirectUrl: redirectUrl,
								RedirectPage,
								EssRedirect,
							} = resp.data;
							if (Error != null) {
								this.error = Error;
							} else {
								let channels = this.otpChannels || undefined;
								if (!EssRedirect) {
									if (this.mfaNotSet == undefined) {
										const {authenticated, mfaRequired, otpChannels} = await this.$checkMFACookie(
											redirectUrl,
										);
										this.mfaNotSet = mfaRequired && !authenticated;
										channels = otpChannels;
									}
								}
								if (ClientRedirect && redirectUrl.length) {
									if (this.mfaNotSet) {
										this.$router.push({
											name: "MFA",
											params: {
												redirectUrl,
												otpChannels: channels,
											},
										});
									} else {
										if (!EssRedirect)
											await this.$ajax.post("/legacy/success", null, {withCredentials: true});
										window.location.href = redirectUrl;
									}
								} else if (RedirectPage != null) {
									const queryString = {redirectUrl: redirectUrl || undefined};
									let params = {
										mfaNotSet: this.mfaNotSet || undefined,
										otpChannels: channels,
									};
									this.$router.push({
										name: RedirectPage,
										params,
										query: queryString,
									});
								}
							}
						}
					})
					.catch((err) => {
						if (err.status == 401) {
							this.$router.push({
								name: "Login",
								query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
							});
						}
					})
					.then(() => {
						this.loading = false;
					});
			},
			handlePwdRequestError(err) {
				if (err.status == 401) {
					this.error =
						"The browser session has expired. Redirecting you to the Reset Pasword page...";
				} else this.error = "Something went wrong. Please try again after 5 minutes.";
			},
		},
		mounted() {
			if (
				typeof this.$route.query != "undefined" &&
				typeof this.$route.query.redirectUrl != "undefined"
			) {
				this.redirectUrl = this.$route.query.redirectUrl;
			}
		},
	};
</script>

<template>
	<setup title="Setup Password" :error="error">
		<div class="card-body">
			<passwordPage
				:loading="loading"
				:isMFAV2="isMFAV2"
				v-model="output"
				:handleSubmit="handleFormSubmit"
				:onValidationRequestError="handlePwdRequestError"></passwordPage>
		</div>
	</setup>
</template>
