import axios from "axios";

export default {
    install(Vue, options) {
        // axios.defaults.headers.common["Access-Control-Request-Headers"] = "*";
        axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
        // axios.defaults.headers.common["Accept"] = "*/*";
        if (options && options.handleUnautorized) {
            axios.interceptors.request.use((config) => {
                // config.withCredentials = true;
                return config;
            });
            axios.interceptors.response.use(
                (resp) => {
                    return resp;
                },
                (err) => {
                    switch (err.response.status) {
                        case 302:
                            const { RedirectUrl } = err.response.data;
                            window.location.href = RedirectUrl || "/";
                            break;
                        case 401:
                            setTimeout(() => {
                                window.location.href = "/";
                            }, 3000);
                            break;
                    }
                    return Promise.reject(err.response);
                }
            );
        }

        Vue.prototype.$ajax = axios;
        Vue.ajax = axios;
    },
};

export const APIResponse = {
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
    TOKEN_EXPIRED: "TOKEN_EXPIRED",
    EXPIRED: "EXPIRED",
    UNKNOWN_USER: "UNKNOWN_USER",
    BAD_REQUEST: "BAD_REQUEST",
    NOT_FOUND: "NOT_FOUND",
    SERVER_ERROR: "SERVER_ERROR",
};

export const getErrorMessage = (result, page) => {
    switch (result) {
        case APIResponse.FAILED:
            if (page === "QUESTIONS") {
                return "You need to answer all three security questions correctly before your password can be reset.";
            } else if (page === "PASSWORD") {
                return "Please complete all fields.";
            } else if (page === "SEND_OTP") {
                return "Sending of OTP confirmation code has failed. Please try again later.";
            } else if (page === "VERIFY_OTP") {
                return "You entered the wrong code. Please try again.";
            }
        case APIResponse.TOKEN_EXPIRED:
            return "You are away for several minutes.";
        case APIResponse.EXPIRED:
            return "Confirmation code is already expired.";
        case APIResponse.UNKNOWN_USER:
            return "You need to enter a valid username or email address.";
        case APIResponse.BAD_REQUEST:
            return "Something went wrong. Please try again after 5 minutes.";
        case APIResponse.SERVER_ERROR:
            return "Something went wrong. Please try again after 5 minutes.";
        default:
            return "";
    }
};
